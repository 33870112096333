import * as Sentry from "@sentry/react";
import LoadingOverlay from "react-loading-overlay-ts";
import UseSwipe from "../../hooks/UseSwipe";
import Error from "../../pages/authentication/Error";
import Store from "../../store";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

function tap(
  sidebarVisible: boolean,
  sidebarCollapsed: boolean,
  collapseSidebar: () => void,
) {
  if (sidebarVisible && !sidebarCollapsed && window.innerWidth < 640) {
    collapseSidebar();
  }
}

function swipeRight(
  sidebarVisible: boolean,
  sidebarCollapsed: boolean,
  uncollapseSidebar: () => void,
) {
  if (sidebarVisible && sidebarCollapsed) {
    uncollapseSidebar();
  }
}

function swipeLeft(
  sidebarVisible: boolean,
  sidebarCollapsed: boolean,
  collapseSidebar: () => void,
) {
  if (sidebarVisible && !sidebarCollapsed) {
    collapseSidebar();
  }
}

const NavigationContainer = ({
  children,
  sidebar,
}: {
  children: any;
  sidebar?: any;
}) => {
  const fragment = children;
  const {
    isLoading,
    sidebarVisible,
    sidebarCollapsed,
    collapseSidebar,
    uncollapseSidebar,
  } = Store();
  const swipeHandlers = UseSwipe({
    onSwipedLeft: () =>
      swipeLeft(sidebarVisible, sidebarCollapsed, collapseSidebar),
    onSwipedRight: () =>
      swipeRight(sidebarVisible, sidebarCollapsed, uncollapseSidebar),
  });
  return (
    <Sentry.ErrorBoundary fallback={<Error />} showDialog={true}>
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Loading..."
        styles={{
          wrapper: { height: "100vh", display: "flex" },
        }}
      >
        <div className="relative flex flex-col w-full overflow-hidden h-dvh max-h-dvh">
          <NavigationBar />
          <div className="flex flex-row flex-grow overflow-hidden">
            {sidebar && <div>{sidebar}</div>}
            <div
              className="flex flex-col flex-auto overflow-auto"
              onClick={() =>
                tap(sidebarVisible, sidebarCollapsed, collapseSidebar)
              }
              {...swipeHandlers}
            >
              <div
                className={`p-3 lg:min-w-[1024px] ${sidebarVisible ? "min-w-[360px]" : ""}`.trim()}
              >
                {fragment}
              </div>
              <div className="flex-grow"></div>
              <Footer />
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </Sentry.ErrorBoundary>
  );
};

export default NavigationContainer;
