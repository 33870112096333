import Stripe from "stripe";
import { auth } from "../firebase";

const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

export const getPaymentAccount = async (accountId: string) => {
  try {
    const url = new URL(`${baseUrl}/getPaymentAccount`);
    url.searchParams.append("accountId", accountId);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      },
    });
    const data = await response.json();
    return data as Stripe.Account;
  } catch (error) {
    console.error(error);
  }
};

export const createPaymentAccount = async () => {
  try {
    const url = new URL(`${baseUrl}/createPaymentAccount`);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      },
    });
    const data = await response.json();
    return data.account as string;
  } catch (error) {
    console.error(error);
  }
};

export const linkPamentAccount = async (
  accountId: string,
  locationId: string,
) => {
  try {
    const url = new URL(`${baseUrl}/linkPaymentAccount`);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountId: accountId,
        locationId: locationId,
      }),
    });
    const data = await response.json();
    return data as Stripe.AccountLink;
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentProducts = async (accountId: string) => {
  const url = new URL(`${baseUrl}/getProducts`);
  url.searchParams.append("accountId", accountId);
  const response = await fetch(url, {
    method: "GET",
  });
  const data = (await response.json()).data;
  return data as Stripe.Product[];
};

export const createCheckoutSession = async (
  accountId: string,
  priceId: string,
  customerEmail: string,
  successUrl: string,
  cancelUrl: string,
) => {
  const url = new URL(`${baseUrl}/createCheckoutSession`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      accountId: accountId,
      priceId: priceId,
      customerEmail: customerEmail,
      successUrl: successUrl,
      cancelUrl: cancelUrl,
    }),
  });
  console.log(priceId);
  const data = await response.json();
  return data as Stripe.Checkout.Session;
};
