import { auth } from "@/firebase";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AddAppointmentFormComponent from "../../components/appointments/appointment-form/AddAppointmentFormComponent";
import AppointmentFormComponents from "../../components/appointments/appointment-form/AppointmentFormComponents";
import AppointmentFormPreview from "../../components/appointments/appointment-form/AppointmentFormPreview";
import EditAppointmentFormComponent from "../../components/appointments/appointment-form/EditAppointmentFormComponent";
import NavigationContainer from "../../components/layout/NavigationContainer";
import LocationSidebar from "../../components/locations/LocationSidebar";
import AppointmentComponentProperties from "../../models/appointments/AppointmentComponentProperties";
import AppointmentTemplate from "../../models/appointments/AppointmentTemplate";
import AppointmentTemplateComponent from "../../models/appointments/AppointmentTemplateComponent";
import {
  createOrUpdateAppointmentTemplate,
  deleteAppointmentTemplateComponentById,
  getAppointmentTemplatesByLocationId,
} from "../../services/AppointmentService";
import Store from "../../store";
import { getParameterByName } from "../../utils/Helpers";

const AppointmentForm = () => {
  const { setIsLoading } = Store();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [componentProperties, setComponentProperties] =
    useState<AppointmentComponentProperties>();
  const locationId: string = getParameterByName("locationId");
  const [template, setTemplate] = useState<AppointmentTemplate>(
    new AppointmentTemplate(),
  );

  const addTemplateComponent = async (
    component: AppointmentTemplateComponent,
  ) => {
    template.components.push(component);
    setTemplate({ ...template } as AppointmentTemplate);
    await createOrUpdateAppointmentTemplate(
      Object.assign(new AppointmentTemplate(), template),
    );
  };

  const editTemplateComponent = async (
    component: AppointmentTemplateComponent,
  ) => {
    const index = template.components.findIndex(
      (comp) =>
        comp.properties.componentId === component.properties.componentId,
    );
    if (index !== -1) {
      const updatedComponents = [...template.components];
      updatedComponents[index] = component;
      setTemplate({
        ...template,
        components: updatedComponents,
      } as AppointmentTemplate);
      await createOrUpdateAppointmentTemplate(
        Object.assign(new AppointmentTemplate(), {
          ...template,
          components: updatedComponents,
        }),
      );
    } else {
      console.error("Component with the specified ID not found.");
    }
  };

  const openEditModalDialog = (component: AppointmentTemplateComponent) => {
    setComponentProperties(component.properties);
    setIsModalOpen(true);
  };

  const deleteTemplateComponent = async (
    component: AppointmentTemplateComponent,
  ) => {
    const index = template.components.findIndex(
      (comp) =>
        comp.properties.componentId === component.properties.componentId,
    );
    template.components.splice(index, 1);
    setTemplate({ ...template } as AppointmentTemplate);
    await deleteAppointmentTemplateComponentById(
      template.id,
      component.properties.componentId,
    );
  };

  const reorderTemplateComponent = async (
    component: AppointmentTemplateComponent,
    direction: "up" | "down",
  ) => {
    const index = template.components.findIndex(
      (comp) =>
        comp.properties.componentId === component.properties.componentId,
    );
    if (index !== -1) {
      const updatedComponents = [...template.components];
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex >= 0 && newIndex < updatedComponents.length) {
        updatedComponents.splice(index, 1);
        updatedComponents.splice(newIndex, 0, component);
        setTemplate({
          ...template,
          components: updatedComponents,
        } as AppointmentTemplate);
        await createOrUpdateAppointmentTemplate(
          Object.assign(new AppointmentTemplate(), {
            ...template,
            components: updatedComponents,
          }),
        );
      }
    } else {
      console.error("Component with the specified ID not found.");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const appointmentTemplatesArray =
          await getAppointmentTemplatesByLocationId(locationId);
        if (appointmentTemplatesArray.length > 0) {
          setTemplate(appointmentTemplatesArray[0]);
        } else {
          template.id = uuidv4();
          template.locationId = locationId;
          template.createdByUserId = auth?.currentUser?.uid;
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId} />}>
      <div className="text-4xl text-monkey-purple">Appointment Form</div>
      <div className="flex">
        <div className="px-10 basis-1/2 grow">
          <div className="flex my-4 text-2xl">
            <div className="grow">Components</div>
            <div>
              <AddAppointmentFormComponent onSuccess={addTemplateComponent} />
            </div>
          </div>
          <EditAppointmentFormComponent
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSuccess={editTemplateComponent}
            properties={componentProperties}
          />
          <AppointmentFormComponents
            components={template.components}
            editComponent={openEditModalDialog}
            deleteComponent={deleteTemplateComponent}
            reorderComponent={reorderTemplateComponent}
          />
        </div>
        <div className="hidden px-10 basis-1/2 sm:block">
          <div className="my-4 text-2xl">Preview</div>
          <AppointmentFormPreview components={template.components} />
        </div>
      </div>
    </NavigationContainer>
  );
};

export default AppointmentForm;
