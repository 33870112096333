import Location from "@/models/locations/Location";
import LocationEmployee from "@/models/locations/LocationEmployee";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { IoMdAdd } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import Button from "../../ui/Button";
import TextField from "../../ui/TextField";
import PlaceholderImage from "/images/upload-image-placeholder.jpg";

const NewLocationEmployees = (props: {
  onBack: (location?: Location) => void;
  onContinue: (location: Location) => void;
  location: Location;
}) => {
  const { onBack, onContinue, location } = props;
  const [noClick, setNoClick] = useState(false);
  const [employee, setEmployee] = useState(new LocationEmployee());
  const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [updatedLocation, setUpdatedLocation] = useState<Location>(location);
  const avatarEditor = useRef<AvatarEditor>(null);

  const editEmployee = (index: number) => {
    setEmployee(updatedLocation.employees[index]);
    setShowEmployeeDetails(true);
    setNoClick(false);
  };

  const addEmployee = () => {
    setEmployee({} as LocationEmployee);
    setShowEmployeeDetails(true);
    setNoClick(false);
  };

  const saveEmployee = () => {
    if (avatarEditor.current && employee.image) {
      const canvas = avatarEditor.current.getImageScaledToCanvas();
      const dataUrl = canvas.toDataURL();
      employee.image = dataUrl;
    } else {
      employee.image = "";
    }
    setEmployee({
      ...employee,
      firstName: employee.firstName,
      lastName: employee.lastName,
    });
    if (employee.id) {
      setUpdatedLocation({
        ...updatedLocation,
        employees: updatedLocation.employees.map((e) =>
          e.id === employee!.id ? employee : e,
        ),
      });
    } else {
      employee.id = uuidv4();
      setUpdatedLocation({
        ...updatedLocation,
        employees: [...updatedLocation.employees, employee],
      });
    }
    setShowEmployeeDetails(false);
  };

  const removeEmployee = () => {
    setUpdatedLocation({
      ...updatedLocation,
      employees: updatedLocation.employees.filter((e) => e.id !== employee.id),
    });
    setShowEmployeeDetails(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setEmployee({ ...employee, image: URL.createObjectURL(acceptedFiles[0]) });
  };

  const onImageChange = () => {
    setNoClick(true);
  };

  return (
    <div className="max-w-screen-sm mx-auto mt-[50px]">
      {!showEmployeeDetails && (
        <div>
          Who works at this location? Don't forget to include yourself!
          <div className="my-4 text-lg text-monkey-purple">Employees</div>
          <div className="flex flex-wrap">
            {updatedLocation.employees.map((employee, index) => {
              return (
                <div key={index} className="mb-4 ml-4 text-xl rounded-full">
                  <button
                    onClick={() => editEmployee(index)}
                    className="rounded-full w-[150px] h-[150px]"
                  >
                    <div>
                      <img
                        src={employee.image || PlaceholderImage}
                        className="rounded-full"
                      ></img>
                      <div>{employee.firstName}</div>
                    </div>
                  </button>
                </div>
              );
            })}
            <div className="mb-4 ml-4">
              <button
                onClick={addEmployee}
                className="rounded-full bg-monkey-gray w-[150px] h-[150px]"
              >
                <IoMdAdd className="m-auto text-[150px] text-white" />
                <div className="text-xl">Add Employee</div>
              </button>
            </div>
          </div>
          <div className="mt-4 space-x-4 text-center">
            <Button
              secondary
              type="button"
              onClick={() => onBack(updatedLocation)}
            >
              Back
            </Button>
            <Button
              primary
              type="button"
              onClick={() => onContinue(updatedLocation)}
            >
              Finish
            </Button>
          </div>
        </div>
      )}
      {showEmployeeDetails && (
        <div>
          <div className="max-w-screen-sm mx-auto mt-8 text-2xl text-center text-monkey-purple">
            New Employee
          </div>
          <div className="flex justify-center">
            <Dropzone onDrop={onDrop} noKeyboard noClick={noClick}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <AvatarEditor
                    width={150}
                    height={150}
                    image={employee.image || PlaceholderImage}
                    ref={avatarEditor}
                    border={25}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={1}
                    rotate={0}
                    borderRadius={100}
                    onImageChange={onImageChange}
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <div>
            <TextField
              elementId="employee-first-name"
              labelText="First name"
              required={true}
              onInput={(value: string) =>
                setEmployee({ ...employee, firstName: value })
              }
              defaultValue={employee.firstName}
              autoFocus={true}
            />
          </div>
          <div>
            <TextField
              elementId="employee-last-name"
              labelText="Last name"
              required={true}
              onInput={(value: string) =>
                setEmployee({ ...employee, lastName: value })
              }
              defaultValue={employee.lastName}
            />
          </div>
          <div className="flex justify-center mt-8 space-x-4">
            {employee.id && (
              <Button warning type="button" onClick={removeEmployee}>
                Delete
              </Button>
            )}
            <Button
              secondary
              type="button"
              onClick={() => setShowEmployeeDetails(false)}
            >
              Cancel
            </Button>
            <Button primary type="button" onClick={saveEmployee}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewLocationEmployees;
