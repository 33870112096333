import Button from "@/components/ui/Button";
import Calendar from "@/components/ui/Calendar";
import TimeSelect from "@/components/ui/TimeSelect";
import Appointment from "@/models/appointments/Appointment";
import { useState } from "react";

const AppointmentBookingDate = (props: {
  appointment: Appointment;
  onBack: () => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { appointment, onBack, onSubmit } = props;
  const [appointmentDate, setAppointmentDate] = useState(appointment.date);
  const [appointmentTime, setAppointmentTime] = useState(appointment.time);
  const [updatedAppointment, setUpdatedAppointment] = useState(appointment);

  const setDate = (date: Date | undefined) => {
    setAppointmentDate(date || new Date());
    setUpdatedAppointment({
      ...updatedAppointment,
      date: date || new Date(),
    });
  };

  const setTime = (time: string) => {
    setAppointmentTime(time);
    setUpdatedAppointment({
      ...updatedAppointment,
      time: time,
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(updatedAppointment);
      }}
    >
      <div className="mt-6 text-center">Select date and time</div>
      <div className="mt-4">
        <Calendar
          mode="single"
          selected={appointmentDate}
          onSelect={(date) => setDate(date)}
          className="border rounded-md"
        />
      </div>
      <div className="mt-4 text-center">
        <TimeSelect
          time={appointmentTime}
          required={true}
          setTime={(time) => setTime(time)}
        />
      </div>
      <div className="flex justify-center mt-4 space-x-4">
        <Button secondary type="button" onClick={onBack}>
          Back
        </Button>
        <Button primary type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default AppointmentBookingDate;
