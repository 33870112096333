import { useState } from "react";
import AppointmentComponentProperties from "../../../models/appointments/AppointmentComponentProperties";
import AppointmentTemplateComponent from "../../../models/appointments/AppointmentTemplateComponent";
import Button from "../../ui/Button";
import AppointmentFormModalDialog from "./AppointmentFormModalDialog";

const AddAppointmentFormComponent = (props: {
  properties?: AppointmentComponentProperties;
  onSuccess: (component: AppointmentTemplateComponent) => void;
}) => {
  const { properties, onSuccess } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button
        secondary
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="text-lg !min-w-[40px]"
      >
        <div className="hidden px-4 lg:block">Add Component</div>
        <div className="lg:hidden">+</div>
      </Button>
      <AppointmentFormModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        properties={properties}
        onSuccess={(component: AppointmentTemplateComponent) => {
          onSuccess(component);
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default AddAppointmentFormComponent;
