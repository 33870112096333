function DisplayPaymentAccount() {
  return (
    <div>
      <div>Your payments account has been configured successfully.</div>
      <div>
        <a href="https://dashboard.stripe.com">
          Click here to access your payments Dashboard.
        </a>
      </div>
    </div>
  );
}

export default DisplayPaymentAccount;
