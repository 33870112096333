const Label = (props: {
  htmlFor?: string;
  children: string;
  className?: string;
}) => {
  const { htmlFor, children, className } = props;
  return (
    <label
      htmlFor={htmlFor}
      className={`block mb-2 text-sm font-medium text-monkey-purple ${className ?? ""}`.trim()}
    >
      {children}
    </label>
  );
};

export default Label;
