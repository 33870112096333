import Appointment from "@/models/appointments/Appointment";
import AppointmentMultiSelectComponent from "@/models/appointments/AppointmentMultiSelectComponent";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  or,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore/lite";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";
import AppointmentTemplate from "../models/appointments/AppointmentTemplate";
import AppointmentTemplateComponent from "../models/appointments/AppointmentTemplateComponent";
import AppointmentTextFieldComponent from "../models/appointments/AppointmentTextFieldComponent";

const db = getFirestore(app);

export const createAppointmentTemplate = async (
  template: AppointmentTemplate,
) => {
  try {
    template.id = uuidv4();
    await setDoc(
      doc(db, "appointmentTemplates", template.id),
      template.toFirestoreObject(),
    );
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateAppointmentTemplate = async (
  template: AppointmentTemplate,
) => {
  try {
    const docRef = doc(db, "appointmentTemplates", template.id);
    await updateDoc(docRef, template.toFirestoreObject());
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const createOrUpdateAppointmentTemplate = async (
  template: AppointmentTemplate,
) => {
  const docRef = await getDoc(
    doc(db, "appointmentTemplates", template.id),
  ).catch(console.error);
  if (docRef && docRef.exists()) {
    updateAppointmentTemplate(template);
  } else {
    createAppointmentTemplate(template);
  }
};

export const getAppointmentTemplateById = async (id: string) => {
  const docRef = doc(db, "appointmentTemplates", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const template = new AppointmentTemplate();
    template.id = docSnap.id;
    template.name = docSnap.data().name;
    template.components = docSnap.data().components.map((x: any) => {
      switch (x.properties.componentType) {
        case "TextField":
          return new AppointmentTextFieldComponent(x.properties);
        case "MultiSelect":
          return new AppointmentMultiSelectComponent(x.properties);
        default:
          return null;
      }
    });
    template.locationId = docSnap.data().locationId;
    template.createdByUserId = docSnap.data().createdByUserId;
    return template;
  } else {
    console.error("No such document!");
  }
};

export const getAppointmentTemplatesByLocationId = async (
  locationId: string | undefined,
) => {
  const appointmentTemplates = collection(db, "appointmentTemplates");
  const querySnapshot = await getDocs(
    query(appointmentTemplates, where("locationId", "==", locationId)),
  );

  const appointmentTemplatesArray = [] as AppointmentTemplate[];
  querySnapshot.forEach((doc) => {
    appointmentTemplatesArray.push({
      id: doc.id,
      name: doc.data().name,
      components: doc.data().components.map((x: any) => {
        switch (x.properties.componentType) {
          case "TextField":
            return new AppointmentTextFieldComponent(x.properties);
          case "MultiSelect":
            return new AppointmentMultiSelectComponent(x.properties);
          default:
            return null;
        }
      }),
      locationId: doc.data().locationId,
      createdByUserId: doc.data().createdByUserId,
    } as AppointmentTemplate);
  });

  return appointmentTemplatesArray;
};

export const getAppointmentTemplatesByUserIdOrLocationId = async (
  userId: string | undefined,
  locationId: string | undefined,
) => {
  const appointmentTemplates = collection(db, "appointmentTemplates");

  const combinedQuery = query(
    appointmentTemplates,
    or(
      where("createdByUserId", "==", userId),
      where("locationId", "==", locationId),
    ),
  );

  const querySnapshot = await getDocs(combinedQuery);
  const appointmentTemplatesArray = [] as AppointmentTemplate[];
  querySnapshot.forEach((doc) => {
    appointmentTemplatesArray.push({
      id: doc.id,
      name: doc.data().name,
      components: doc.data().components.map((x: any) => {
        switch (x.properties.componentType) {
          case "TextField":
            return new AppointmentTextFieldComponent(x.properties);
          case "MultiSelect":
            return new AppointmentMultiSelectComponent(x.properties);
          default:
            return null;
        }
      }),
      locationId: doc.data().locationId,
      createdByUserId: doc.data().createdByUserId,
    } as AppointmentTemplate);
  });

  return appointmentTemplatesArray;
};

export const deleteAppointmentTemplateById = async (id: string) => {
  try {
    await setDoc(doc(db, "appointmentTemplates", id), { deleted: true });
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export const deleteAppointmentTemplateComponentById = async (
  templateId: string,
  componentId: string,
) => {
  const template = await getAppointmentTemplateById(templateId);
  if (template !== undefined) {
    template.components = template.components.filter(
      (component: AppointmentTemplateComponent) =>
        component.properties.componentId !== componentId,
    );
    await updateAppointmentTemplate(template);
  }
};

export const createAppointment = async (appointment: Appointment) => {
  appointment.id = uuidv4();
  await setDoc(
    doc(db, "appointments", appointment.id),
    JSON.parse(JSON.stringify(appointment)),
  ).catch(console.error);
};
