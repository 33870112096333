import { linkPamentAccount } from "@/services/PaymentService";
import { useEffect, useState } from "react";

function LinkPaymentAccount(props: { accountId: string; locationId: string }) {
  const { accountId, locationId } = props;
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const account = await linkPamentAccount(accountId, locationId);
        return account;
      } catch (error) {
        console.error(error);
      }
    };
    fetchData().then((account) => {
      setUrl(account?.url);
    });
  }, []);

  return (
    <div>
      <div>Great! First, we'll need a couple of details from you.</div>
      <div>
        <a href={url}>Click here to finish setting up your payment account.</a>
      </div>
    </div>
  );
}

export default LinkPaymentAccount;
