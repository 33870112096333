import SignUp from "../../components/authentication/SignUp";
import SplitScreen from "../../components/layout/SplitScreen";

const GettingStarted = () => {
  return (
    <SplitScreen>
      <div className="overflow-hidden bg-center bg-no-repeat bg-cover h-dvh bg-fruit-stand"></div>
      <SignUp />
    </SplitScreen>
  );
};

export default GettingStarted;
