import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Store from "../../store";

const LocationSidebar = (props: { locationId: string }) => {
  const { locationId } = props;
  const { sidebarCollapsed } = Store();
  return (
    <Sidebar
      className="h-dvh"
      collapsed={sidebarCollapsed}
      collapsedWidth="0px"
    >
      <Menu>
        <MenuItem
          component={
            <Link to={`/locations/overview?locationId=${locationId}`} />
          }
        >
          Overview
        </MenuItem>
        <MenuItem
          component={
            <Link to={`/locations/appointment-form?locationId=${locationId}`} />
          }
        >
          Appointment Form
        </MenuItem>
        <MenuItem
          component={
            <Link to={`/locations/payments?locationId=${locationId}`} />
          }
        >
          Payments
        </MenuItem>
        <MenuItem component={<Link to="/locations" />}>Locations</MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default LocationSidebar;
