import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationContainer from "../../components/layout/NavigationContainer";
import LocationSidebar from "../../components/locations/LocationSidebar";
import Button from "../../components/ui/Button";
import Location from "../../models/locations/Location";
import {
  deleteLocationById,
  getLocationById,
} from "../../services/LocationService";
import { getParameterByName } from "../../utils/Helpers";

const Overview = () => {
  const navigate = useNavigate();
  const locationId: string = getParameterByName("locationId");
  const [location, setLocation] = useState<Location | null>(null);

  const deleteLocation = async () => {
    try {
      await deleteLocationById(locationId);
      navigate("/locations");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const location = await getLocationById(locationId);
        setLocation(location);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId} />}>
      <h1>{location?.name}</h1>
      <div>
        <a href={`/b/${location?.id}`}>Booking Link</a>
      </div>
      <div>
        <Button primary type="button" onClick={deleteLocation}>
          Delete Location
        </Button>
      </div>
    </NavigationContainer>
  );
};

export default Overview;
