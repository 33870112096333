import AppointmentBookingConfirmation from "@/components/appointments/appointment-booking/AppointmentBookingConfirmation";
import AppointmentBookingDate from "@/components/appointments/appointment-booking/AppointmentBookingDate";
import AppointmentBookingEmployees from "@/components/appointments/appointment-booking/AppointmentBookingEmployees";
import AppointmentBookingServices from "@/components/appointments/appointment-booking/AppointmentBookingServices";
import AppointmentBookingSummary from "@/components/appointments/appointment-booking/AppointmentBookingSummary";
import AppointmentBookingTemplate from "@/components/appointments/appointment-booking/AppointmentBookingTemplate";
import PageContainer from "@/components/layout/PageContainer";
import Appointment from "@/models/appointments/Appointment";
import LocationEmployee from "@/models/locations/LocationEmployee";
import {
  createAppointment,
  getAppointmentTemplatesByLocationId,
} from "@/services/AppointmentService";
import { getLocationById } from "@/services/LocationService";
import { getFileURL } from "@/services/StorageService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Location from "../../models/locations/Location";

const AppointmentBooking = () => {
  const { setIsLoading } = Store();
  const { locationId } = useParams();
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState(new Location());
  const [appointment, setAppointment] = useState(new Appointment());

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId ?? "");
      location.image = (await getFileURL(location.image)) ?? "";
      location.appointmentTemplate = (
        await getAppointmentTemplatesByLocationId(locationId)
      )[0];
      setLocation(location);
      setAppointment({
        ...appointment,
        appointmentTemplate: location.appointmentTemplate,
      });
    };
    fetchData().then(() => setIsLoading(false));
  }, []);

  const setEmployee = (employee: LocationEmployee) => {
    setAppointment({ ...appointment, employee: employee });
    setStep(step + 1);
  };

  const onSubmit = (updatedAppointment: Appointment) => {
    createAppointment(updatedAppointment);
  };

  return (
    <PageContainer>
      <div className="max-w-screen-sm mx-auto mt-8">
        <div className="flex flex-col items-center">
          <div className="text-5xl">{location.name}</div>
          <div className="text-2xl text-center">{location.address}</div>
          {step === 1 && (
            <>
              <img className="mt-8" src={location.image} />
              <AppointmentBookingEmployees
                employees={location.employees}
                setEmployee={setEmployee}
              />
            </>
          )}
          {step === 2 && (
            <AppointmentBookingDate
              appointment={appointment}
              onBack={() => setStep(step - 1)}
              onSubmit={(appointment: Appointment) => {
                setAppointment(appointment);
                setStep(step + 1);
              }}
            />
          )}
          {step === 3 && (
            <AppointmentBookingServices
              appointment={appointment}
              accountId={location.stripeAccountId}
              onBack={(appointment: Appointment) => {
                setAppointment(appointment);
                setStep(step - 1);
              }}
              onSubmit={(appointment: Appointment) => {
                setAppointment(appointment);
                if (location.appointmentTemplate) {
                  setStep(step + 1);
                } else {
                  setStep(step + 2);
                }
              }}
            />
          )}
          {step === 4 && (
            <AppointmentBookingTemplate
              appointment={appointment}
              onBack={(appointment: Appointment) => {
                setAppointment(appointment);
                setStep(step - 1);
              }}
              onSubmit={(appointment: Appointment) => {
                setAppointment(appointment);
                setStep(step + 1);
              }}
            ></AppointmentBookingTemplate>
          )}
          {step === 5 && (
            <AppointmentBookingSummary
              location={location}
              appointment={appointment}
              onBack={(appointment: Appointment) => {
                setAppointment(appointment);
                setStep(step - 1);
              }}
              onSubmit={(appointment: Appointment) => {
                onSubmit(appointment);
              }}
            ></AppointmentBookingSummary>
          )}
          {step === 6 && <AppointmentBookingConfirmation />}
        </div>
      </div>
    </PageContainer>
  );
};

export default AppointmentBooking;
