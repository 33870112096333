import { useRouteError } from "react-router-dom";

type Error = {
  statusText?: string;
  message: string;
};

const Error = () => {
  const error = useRouteError() as Error;
  console.error(error);

  return (
    <div id="error-page">
      {import.meta.env.MODE === "development" && (
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      )}
      {import.meta.env.MODE !== "development" && (
        <div>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
        </div>
      )}
    </div>
  );
};

export default Error;
