import AppointmentTemplateComponent from "./AppointmentTemplateComponent";

class AppointmentTemplate {
  id: string = "";
  name: string = "";
  components: AppointmentTemplateComponent[] = [];
  locationId: string = "";
  createdByUserId: string | undefined = "";

  toFirestoreObject() {
    return {
      id: this.id,
      name: this.name || "default",
      components: this.components.map((obj) => JSON.parse(JSON.stringify(obj))),
      locationId: this.locationId,
      createdByUserId: this.createdByUserId,
    };
  }
}

export default AppointmentTemplate;
