const Footer = () => {
  return (
    <div className="sticky left-0 hidden p-3 border-t-2 border-b-black sm:block">
      <div className="flex justify-between">
        <p>&copy; {new Date().getUTCFullYear()} Appointment Monkey Inc.</p>
        <p>
          {import.meta.env.MODE}:{import.meta.env.VITE_APP_VERSION}
        </p>
      </div>
    </div>
  );
};

export default Footer;
