import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationSidebar from "@/components/locations/LocationSidebar";
import CreatePaymentAccount from "@/components/payments/CreatePaymentAccount";
import DisplayPaymentAccount from "@/components/payments/DisplayPaymentAccount";
import LinkPaymentAccount from "@/components/payments/LinkPaymentAccount";
import { auth } from "@/firebase";
import { getPaymentAccount } from "@/services/PaymentService";
import { getUserById } from "@/services/UserService";
import Store from "@/store";
import { getParameterByName } from "@/utils/Helpers";
import { useEffect, useState } from "react";
import Stripe from "stripe";

const Payments = () => {
  const locationId: string = getParameterByName("locationId");
  const { isLoading, setIsLoading } = Store();
  const [refresh, setRefresh] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState<Stripe.Account | null>(
    null,
  );

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const user = await getUserById(auth?.currentUser?.uid);
        if (user?.stripeAccountId) {
          const paymentAccount = await getPaymentAccount(user.stripeAccountId);
          if (paymentAccount) {
            setPaymentAccount(paymentAccount);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [refresh]);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId} />}>
      <h1>Payments</h1>
      {isLoading && <div>Loading...</div>}
      {!isLoading && !paymentAccount && (
        <CreatePaymentAccount setRefresh={setRefresh} />
      )}
      {!isLoading && paymentAccount && !paymentAccount.details_submitted && (
        <LinkPaymentAccount
          accountId={paymentAccount.id}
          locationId={locationId}
        />
      )}
      {!isLoading && paymentAccount && paymentAccount.details_submitted && (
        <DisplayPaymentAccount />
      )}
    </NavigationContainer>
  );
};

export default Payments;
