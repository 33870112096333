import {
  applyActionCode,
  Auth,
  checkActionCode,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useState } from "react";
import PageContainer from "../layout/PageContainer";

function handleRecoverEmail(
  auth: Auth,
  actionCode: string,
  setEmailRecoverConfirmationMessage: (message: string) => void,
  setPasswordResetConfirmationMessage: (message: string) => void,
) {
  let restoredEmail: string | undefined | null;

  // Confirm the action code is valid.
  checkActionCode(auth, actionCode)
    .then((info) => {
      // Get the restored email address.
      restoredEmail = info["data"]["email"];

      // Revert to the old email.
      return applyActionCode(auth, actionCode);
    })
    .then(() => {
      // Account email reverted to restoredEmail
      setEmailRecoverConfirmationMessage(
        "Your email address has been successfully restored.",
      );

      if (restoredEmail) {
        sendPasswordResetEmail(auth, restoredEmail).then(() => {
          setPasswordResetConfirmationMessage(
            "A link to reset your password has been sent to your restored email address.",
          );
        });
      }
    })
    .catch((error) => {
      console.error(error);
      setEmailRecoverConfirmationMessage(
        "Uh oh! Your email recovery code is invalid or expired. Please try again.",
      );
    });
}

const RecoverEmail = ({
  auth,
  actionCode,
}: {
  auth: Auth;
  actionCode: string;
  continueUrl: string;
  lang: string;
}) => {
  const [emailRecoverConfirmationMessage, setEmailRecoverConfirmationMessage] =
    useState<string | null>(null);
  const [
    passwordResetConfirmationMessage,
    setPasswordResetConfirmationMessage,
  ] = useState<string | null>(null);

  handleRecoverEmail(
    auth,
    actionCode,
    setEmailRecoverConfirmationMessage,
    setPasswordResetConfirmationMessage,
  );

  return (
    <PageContainer>
      <div className="w-full max-w-screen-sm px-5 mx-auto mt-8 text-center">
        <div>{emailRecoverConfirmationMessage}</div>
        <div>{passwordResetConfirmationMessage}</div>
      </div>
    </PageContainer>
  );
};

export default RecoverEmail;
