import * as Sentry from "@sentry/react";
import LoadingOverlay from "react-loading-overlay-ts";
import Error from "../../pages/authentication/Error";
import Store from "../../store";

const PageContainer = ({ children }: { children: any }) => {
  const fragment = children;
  const { isLoading } = Store();
  return (
    <Sentry.ErrorBoundary fallback={<Error />} showDialog={true}>
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Loading..."
        styles={{
          wrapper: { height: "100vh", display: "flex" },
        }}
      >
        {fragment}
      </LoadingOverlay>
    </Sentry.ErrorBoundary>
  );
};

export default PageContainer;
