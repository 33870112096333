import AppointmentTemplateComponent from "../../../models/appointments/AppointmentTemplateComponent";

export default function AppointmentFormPreview(props: {
  components: AppointmentTemplateComponent[];
}) {
  const { components } = props;
  return (
    <div>
      {components.map((component, index) => (
        <div key={index} className="flex">
          {component.render()}
        </div>
      ))}
    </div>
  );
}
