import MultiSelect from "@/components/ui/MultiSelect";
import AppointmentMultiSelectComponentProperties from "./AppointmentMultiSelectComponentProperties";
import AppointmentTemplateComponent from "./AppointmentTemplateComponent";

class AppointmentMultiSelectComponent implements AppointmentTemplateComponent {
  public readonly properties: AppointmentMultiSelectComponentProperties;

  constructor(properties: AppointmentMultiSelectComponentProperties) {
    this.properties = properties;
  }

  render() {
    return (
      <MultiSelect
        prompt={this.properties.prompt}
        items={this.properties.items}
        selectedItems={[]}
      />
    );
  }
}

export default AppointmentMultiSelectComponent;
