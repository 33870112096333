import { Navigate } from "react-router-dom";
import Store from "../../store";

const PrivateRoute = ({ children }: { children: any }) => {
  const { userIsLoggedIn } = Store();

  if (userIsLoggedIn) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
