import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";
import Location from "../models/locations/Location";
import { uploadFileFromDataURL } from "./StorageService";

const db = getFirestore(app);

export const getLocationById = async (locationId: string) => {
  const locationRef = doc(db, "locations", locationId);
  const locationSnapshot = await getDoc(locationRef);
  const location = new Location().fromFirestoreObject(locationSnapshot.data());
  return location;
};

export const getLocationsByUserId = async (userId: string | undefined) => {
  const locations = collection(db, "locations");
  const locationsQuery = query(
    locations,
    where("createdByUserId", "==", userId),
  );
  const querySnapshot = await getDocs(locationsQuery);
  const locationsArray = [] as Location[];
  querySnapshot.forEach((doc) => {
    locationsArray.push({
      id: doc.id,
      name: doc.data().name,
      image: doc.data().image,
      address: doc.data().address,
      timeZone: doc.data().timeZone,
      stripeAccountId: doc.data().stripeAccountId,
      createdByUserId: doc.data().createdByUserId,
      employees: doc.data().employees,
      services: doc.data().services,
    } as Location);
  });
  return locationsArray;
};

export const createLocation = async (location: Location) => {
  const imageId = uuidv4();
  const imagePath = `/locations/${location.id}/${imageId}`;

  await setDoc(
    doc(db, "locations", location.id),
    JSON.parse(JSON.stringify(location)),
  ).catch(console.error);

  if (location.image) {
    await uploadFileFromDataURL(imagePath, location.image).catch(console.error);
    location.image = imagePath;
  }

  for (const employee of location.employees) {
    const imageId = uuidv4();
    const imagePath = `/locations/${location.id}/${imageId}`;

    if (employee.image) {
      await uploadFileFromDataURL(imagePath, employee.image).catch(
        console.error,
      );
      employee.image = imagePath;
    }
  }

  await setDoc(
    doc(db, "locations", location.id),
    JSON.parse(JSON.stringify(location)),
  ).catch(console.error);
};

export const deleteLocationById = async (locationId: string) => {
  await deleteDoc(doc(db, "locations", locationId)).catch(console.error);
};
