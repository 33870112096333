import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./assets/css/index.css";
import PrivateRoute from "./components/authentication/PrivateRoute.tsx";
import { initializeFirebase } from "./firebase.tsx";
import AppointmentBooking from "./pages/appointments/AppointmentBooking.tsx";
import AppointmentForm from "./pages/appointments/AppointmentForm.tsx";
import AuthHandler from "./pages/authentication/AuthHandler.tsx";
import Error from "./pages/authentication/Error.tsx";
import GettingStarted from "./pages/authentication/GettingStarted.tsx";
import Locations from "./pages/locations/Locations.tsx";
import NewLocation from "./pages/locations/NewLocation.tsx";
import Overview from "./pages/locations/Overview.tsx";
import Payments from "./pages/locations/Payments.tsx";
import { initializeSentry } from "./sentry.tsx";

initializeSentry();
initializeFirebase();

const router = createBrowserRouter([
  {
    path: "/",
    element: <GettingStarted />,
    errorElement: <Error />,
  },
  {
    path: "/auth/handler",
    element: <AuthHandler />,
  },
  {
    path: "/b/:locationId",
    element: <AppointmentBooking />,
  },
  {
    path: "/locations",
    element: (
      <PrivateRoute>
        <Locations />
      </PrivateRoute>
    ),
  },
  {
    path: "/locations/new",
    element: (
      <PrivateRoute>
        <NewLocation />
      </PrivateRoute>
    ),
  },
  {
    path: "/locations/overview",
    element: (
      <PrivateRoute>
        <Overview />
      </PrivateRoute>
    ),
  },
  {
    path: "/locations/appointment-form",
    element: (
      <PrivateRoute>
        <AppointmentForm />
      </PrivateRoute>
    ),
  },
  {
    path: "/locations/payments",
    element: (
      <PrivateRoute>
        <Payments />
      </PrivateRoute>
    ),
  },
]);

Modal.setAppElement("#root");

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);
