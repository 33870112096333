import { createPaymentAccount } from "@/services/PaymentService";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import Button from "../ui/Button";

function CreatePaymentAccount(props: {
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setRefresh } = props;
  const paymentMutation = useMutation({
    mutationFn: createPaymentAccount,
  });

  return (
    <div>
      <Button
        primary
        type="button"
        onClick={() => {
          paymentMutation.mutateAsync().then(() => setRefresh(true));
        }}
      >
        Create Payment Account
      </Button>
    </div>
  );
}

export default CreatePaymentAccount;
