import NewLocationDetails from "@/components/locations/new-location/NewLocationDetails";
import NewLocationEmployees from "@/components/locations/new-location/NewLocationEmployees";
import NewLocationServices from "@/components/locations/new-location/NewLocationServices";
import { createLocation } from "@/services/LocationService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationContainer from "../../components/layout/NavigationContainer";
import { auth } from "../../firebase";
import Location from "../../models/locations/Location";

const NewLocation = () => {
  const [step, setStep] = useState(1);
  const [newLocation, setNewLocation] = useState<Location>(new Location());
  const navigate = useNavigate();

  const onBack = (location?: Location) => {
    setStep(step - 1);
    if (location) {
      setNewLocation(location);
    }
  };

  const onContinue = (location: Location) => {
    setStep(step + 1);
    setNewLocation(location);
  };

  const onFinish = (location: Location) => {
    location.createdByUserId = auth?.currentUser?.uid ?? "";
    createLocation(location);
    navigate("/locations");
  };

  return (
    <NavigationContainer>
      {step === 1 && (
        <NewLocationDetails location={newLocation} onContinue={onContinue} />
      )}
      {step === 2 && (
        <NewLocationServices
          location={newLocation}
          onBack={onBack}
          onContinue={onContinue}
        />
      )}
      {step === 3 && (
        <NewLocationEmployees
          location={newLocation}
          onBack={onBack}
          onContinue={onFinish}
        />
      )}
    </NavigationContainer>
  );
};

export default NewLocation;
