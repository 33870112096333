import AppointmentTemplate from "../appointments/AppointmentTemplate";
import LocationEmployee from "./LocationEmployee";
import LocationService from "./LocationService";

class Location {
  id: string = "";
  name: string = "";
  image: string = "";
  address: string = "";
  timeZone: string = "";
  services: LocationService[] = [];
  employees: LocationEmployee[] = [];
  appointmentTemplate: AppointmentTemplate | undefined = undefined;
  stripeAccountId: string = "";
  createdByUserId: string = "";

  readonly fromFirestoreObject: (data: any) => Location = (data: any) => {
    this.id = data.id;
    this.name = data.name;
    this.image = data.image;
    this.address = data.address;
    this.timeZone = data.timeZone;
    this.services = data.services;
    this.employees = data.employees;
    this.stripeAccountId = data.stripeAccountId;
    this.createdByUserId = data.createdByUserId;
    return this;
  };
}

export default Location;
