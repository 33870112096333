import Button from "@/components/ui/Button";
import Appointment from "@/models/appointments/Appointment";
import Location from "@/models/locations/Location";
import { createCheckoutSession } from "@/services/PaymentService";
import Stripe from "stripe";

const AppointmentBookingSummary = (props: {
  location: Location;
  appointment: Appointment;
  onBack: (appointment: Appointment) => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { appointment, location, onBack, onSubmit } = props;
  return (
    <div>
      <div>
        {appointment.date.toDateString()} at {appointment.time}
      </div>
      <div>
        <Button
          primary
          type="button"
          onClick={async () => {
            let session: Stripe.Checkout.Session = await createCheckoutSession(
              location.stripeAccountId,
              appointment?.product?.default_price as string,
              "mwatz122@gmail.com",
              "https://example.com",
              "https://example.com",
            );
            console.log(session?.url);
          }}
        >
          Finish and Pay
        </Button>
      </div>
      <div>
        <div className="flex justify-center space-x-4">
          <Button
            secondary
            type="button"
            onClick={() => {
              onBack(appointment);
            }}
          >
            Back
          </Button>
          <Button
            primary
            type="button"
            onClick={() => {
              onSubmit(appointment);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookingSummary;
