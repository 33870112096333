import Button from "@/components/ui/Button";
import Appointment from "@/models/appointments/Appointment";
import { getPaymentProducts } from "@/services/PaymentService";
import { useEffect, useState } from "react";
import Stripe from "stripe";

const AppointmentBookingServices = (props: {
  appointment: Appointment;
  accountId: string;
  onBack: (appointment: Appointment) => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { appointment, accountId, onBack, onSubmit } = props;
  const [services, setServices] = useState<Stripe.Product[]>([]);

  useEffect(() => {
    const fetchServices = async () => {
      return await getPaymentProducts(accountId);
    };
    fetchServices().then((services) => {
      setServices(services);
    });
  }, []);

  return (
    <div className="w-full px-4 mt-2">
      <div className="my-4 text-center">Select a service</div>
      <div>
        {services.map((service, i) => {
          return (
            <div key={i} className="flex items-baseline my-4">
              <div>{service.name}</div>
              <div className="mx-1 border-b-4 border-dotted grow"></div>
              <Button
                primary
                type="button"
                onClick={() => {
                  appointment.product = service;
                  onSubmit(appointment);
                }}
              >
                Select
              </Button>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Button secondary type="button" onClick={() => onBack(appointment)}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default AppointmentBookingServices;
