import Stripe from "stripe";
import LocationEmployee from "../locations/LocationEmployee";
import LocationService from "../locations/LocationService";
import AppointmentTemplate from "./AppointmentTemplate";

class Appointment {
  id: string = "";
  employee: LocationEmployee = new LocationEmployee();
  appointmentTemplate: AppointmentTemplate | undefined = undefined;
  date: Date = new Date();
  time: string = "";
  services: LocationService[] = [];
  product: Stripe.Product | undefined = undefined;
}

export default Appointment;
