import LocationEmployee from "@/models/locations/LocationEmployee";
import { getFileURL } from "@/services/StorageService";
import { useLayoutEffect, useState } from "react";
import PlaceholderImage from "/images/upload-image-placeholder.jpg";

const AppointmentBookingEmployees = (props: {
  employees: LocationEmployee[];
  setEmployee: (employee: LocationEmployee) => void;
}) => {
  const { employees, setEmployee } = props;
  const [listOfEmployees, setListOfEmployees] = useState<LocationEmployee[]>(
    [],
  );

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const listOfEmployees = await Promise.all(
          employees.map(async (employee) => {
            employee.image = (await getFileURL(employee.image)) ?? "";
            return employee;
          }),
        );
        setListOfEmployees(listOfEmployees);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [employees]);

  return (
    <div className="flex flex-col items-center">
      <div className="my-6 text-xl text-center">
        Who would you like to book an appointment with?
      </div>
      <div className="flex space-x-10">
        {listOfEmployees.map((employee, index) => {
          return (
            <div key={index} className="mb-4 text-xl rounded-full">
              <button
                className="rounded-full w-[150px] h-[150px]"
                onClick={() => setEmployee(employee)}
              >
                <div>
                  <img
                    src={employee.image || PlaceholderImage}
                    className="rounded-full"
                  ></img>
                  <div>{employee.firstName}</div>
                </div>
              </button>
            </div>
          );
        })}
        <div className="mb-4 text-xl rounded-full">
          <button className="rounded-full w-[150px] h-[150px]">
            <div>
              <img src={PlaceholderImage} className="rounded-full"></img>
              <div>First Available</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookingEmployees;
