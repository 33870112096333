const NavigationIcon = ({
  children,
  onClick,
}: {
  children: any;
  onClick: any;
}) => {
  const [icon, text] = children;
  return (
    <div className="flex flex-col justify-center w-16" onClick={onClick}>
      <div className="flex justify-center text-3xl">{icon}</div>
      <div className="text-xs text-center">{text}</div>
    </div>
  );
};

export default NavigationIcon;
