const AppointmentBookingConfirmation = () => {
  return (
    <div>
      <div>Your appointment has been scheduled. Thank you!</div>
      <div>Add to calendar</div>
    </div>
  );
};

export default AppointmentBookingConfirmation;
