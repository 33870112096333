import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import Label from "./Label";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
  "America/New_York": "New York",
};

const TimezoneSelect = (props: {
  timezone: string;
  setTimezone: (timezone: string) => void;
  required?: boolean;
}) => {
  const { timezone, setTimezone } = props;
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const required = props.required === undefined ? false : props.required;

  return (
    <div className="w-full mb-5">
      <Label htmlFor="select-time-zone">Time zone</Label>
      <Select
        name="select-time-zone"
        value={timezone || undefined}
        defaultValue={timezone || undefined}
        onValueChange={(value) => setTimezone(value)}
        required={required}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select time zone" />
        </SelectTrigger>
        <SelectContent>
          {options.map((option, i) => (
            <SelectItem key={i} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default TimezoneSelect;
