import { onAuthStateChanged } from "firebase/auth";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import Store from "../../store";
import Footer from "../layout/Footer";

const SignUp = () => {
  const { setUserIsLoggedIn, setIsLoading } = Store();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      onAuthStateChanged(auth, (u) => {
        if (u) {
          setIsLoading(true);
          setUserIsLoggedIn(true);
          navigate("/locations");
        } else {
          setUserIsLoggedIn(false);
          navigate("/");
        }
      });
    }

    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start("#firebaseui-auth-container", {
      signInSuccessUrl: "/locations",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      tosUrl: "https://app.appointmentmonkey.com/terms",
      privacyPolicyUrl: "https://app.appointmentmonkey.com/privacy",
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          if (authResult.additionalUserInfo.isNewUser) {
            const user = authResult.user;
            user.sendEmailVerification();
          }
          setUserIsLoggedIn(true);
          navigate("/locations");
          return false;
        },
      },
    });
  }, []); // Empty dependency array means this effect runs once on mount and not on updates

  return (
    <div className="flex flex-col h-dvh">
      <div className="w-3/4 px-6">
        <img src="/images/logos/3.png" alt="Appointment Monkey" />
      </div>
      <div className="mt-16 text-3xl text-center">Hey there 👋</div>
      <div id="firebaseui-auth-container"></div>
      <div className="flex-grow"></div>
      <Footer />
    </div>
  );
};

export default SignUp;
