import { useEffect, useState } from "react";
import TextField from "../../components/ui/TextField";
import AppointmentTemplateComponent from "./AppointmentTemplateComponent";
import AppointmentTextFieldComponentProperties from "./AppointmentTextFieldComponentProperties";
class AppointmentTextFieldComponent implements AppointmentTemplateComponent {
  public readonly properties: AppointmentTextFieldComponentProperties;

  constructor(properties: AppointmentTextFieldComponentProperties) {
    this.properties = properties;
  }

  render() {
    const [textFieldValue, setTextFieldValue] = useState(this.properties.value);

    useEffect(() => {
      this.properties.value = textFieldValue;
    }, [textFieldValue]);

    return (
      <TextField
        elementId={this.properties.elementId}
        labelText={this.properties.labelText}
        value={textFieldValue}
        onInput={setTextFieldValue}
      />
    );
  }
}

export default AppointmentTextFieldComponent;
