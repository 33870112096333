import Location from "@/models/locations/Location";
import { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Button from "../../ui/Button";
import TextField from "../../ui/TextField";

const NewLocationServices = (props: {
  onBack: (location?: Location) => void;
  onContinue: (location: Location) => void;
  location: Location;
}) => {
  const { onBack, onContinue, location } = props;

  const [service, setService] = useState("");
  const [newLocation, setNewLocation] = useState<Location>(location);
  const textInput = useRef<HTMLInputElement>(null);

  const addService = () => {
    if (!service.trim()) return;
    setNewLocation({
      ...newLocation,
      services: [...newLocation.services, { name: service.trim() }],
    });
    setService("");
    textInput.current?.focus();
  };

  const removeService = (index: number) => {
    setNewLocation({
      ...newLocation,
      services: newLocation.services.filter((_, i) => i !== index),
    });
  };

  useEffect(() => {
    textInput.current?.focus();
  }, []);

  return (
    <div className="max-w-screen-sm mx-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onContinue({ ...newLocation });
        }}
        className="max-w-screen-sm mx-auto"
      >
        <div className="mt-[50px]">
          What service(s) does this location offer?
        </div>
        <div className="flex mt-5 space-x-5">
          <TextField
            elementId="add-service"
            labelText="Add service"
            onInput={setService}
            value={service}
            ref={textInput}
            onEnter={addService}
          ></TextField>
          <Button
            primary
            type="button"
            title="Add"
            className="!min-w-[40px] mt-[28px]"
            onClick={addService}
          >
            +
          </Button>
        </div>
        <div>
          <div className="mb-2 text-lg text-monkey-purple">Service(s)</div>
          <ul className="flex flex-wrap mb-5">
            {newLocation.services.map((service, index) => (
              <li key={index} className="mx-2 mb-4">
                <div className="flex items-center p-2 rounded-lg bg-monkey-gray-light">
                  {service.name}
                  <button
                    type="button"
                    title="Remove"
                    className="ml-1"
                    onClick={() => removeService(index)}
                  >
                    <IoMdClose />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center space-x-4">
          <Button secondary type="button" onClick={() => onBack(newLocation)}>
            Back
          </Button>
          <Button primary type="submit">
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewLocationServices;
