import { app } from "@/firebase";
import { doc, getDoc, getFirestore } from "firebase/firestore/lite";

const db = getFirestore(app);

export const getUserById = async (userId: string | undefined) => {
  const docRef = doc(db, "users", userId ?? "");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    const user = {
      createdAt: userData.createdAt,
      disabled: userData.disabled,
      displayName: userData.displayName,
      email: userData.email,
      emailVerified: userData.emailVerified,
      id: userData.id,
      image: userData.image,
      name: userData.name,
      phoneNumber: userData.phoneNumber,
      photoURL: userData.photoURL,
      stripeAccountId: userData.stripeAccountId,
    };
    return user;
  } else {
    console.log("No such document!");
    return null;
  }
};
