import React from "react";
import AutoComplete from "react-google-autocomplete";
import Label from "./Label";

const AddressLookup = React.forwardRef(
  (
    props: {
      elementId: string;
      labelText: string;
      value?: string;
      required?: boolean;
      onInput?: any;
      className?: string;
      autoFocus?: boolean;
      defaultValue?: string;
      onPlaceSelected?: any;
    },
    ref: any,
  ) => {
    const {
      elementId,
      labelText,
      value,
      onInput,
      className,
      autoFocus,
      defaultValue,
      onPlaceSelected,
    } = props;
    const required = props.required === undefined ? false : props.required;
    return (
      <div className="w-full mb-5">
        <Label htmlFor={elementId}>{labelText}</Label>
        <AutoComplete
          type="text"
          ref={ref}
          id={elementId}
          required={required}
          onInput={(e) => {
            onInput((e.target as HTMLInputElement).value);
          }}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          options={{ types: ["address"] }}
          apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
          value={value}
          defaultValue={defaultValue}
          onPlaceSelected={(place) => onPlaceSelected(place)}
          autoFocus={autoFocus}
          className={`block w-full h-10 p-2 text-base border-2 rounded-lg border-monkey-purple border:focus-monkey-purple focus:outline-none focus:ring-2 focus:ring-monkey-purple focus:border-monkey-purple ${className ?? ""}`.trim()}
        />
      </div>
    );
  },
);

export default AddressLookup;
