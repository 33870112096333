import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import AppointmentMultiSelectComponentProperties from "@/models/appointments/AppointmentMultiSelectComponentProperties";
import { useEffect, useState } from "react";
import AppointmentComponentProperties from "../../../models/appointments/AppointmentComponentProperties";
import AppointmentTemplateComponent from "../../../models/appointments/AppointmentTemplateComponent";
import AppointmentTextFieldComponentProperties from "../../../models/appointments/AppointmentTextFieldComponentProperties";
import Label from "../../ui/Label";
import ModalDialog from "../../ui/ModalDialog";
import AddAppointmentFormMultiSelect from "../appointment-form/AddAppointmentFormMultiSelect";
import AddAppointmentFormTextField from "../appointment-form/AddAppointmentFormTextField";

const AppointmentFormModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  properties?: AppointmentComponentProperties;
  onSuccess: (component: AppointmentTemplateComponent) => void;
}) => {
  const { isModalOpen, setIsModalOpen, properties, onSuccess } = props;
  const [componentType, setComponentType] = useState(
    properties?.componentType || "TextField",
  );
  useEffect(() => {
    setComponentType(properties?.componentType || "TextField");
  }, [properties]);
  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-2 text-4xl text-center text-monkey-purple">
        Add Component
      </div>
      <div>
        <Label>Component type</Label>
        <Select
          name="component-type"
          value={componentType}
          defaultValue={componentType}
          onValueChange={(value) => setComponentType(value)}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="TextField">Text Field</SelectItem>
            <SelectItem value="MultiSelect">MultiSelect</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {componentType === "TextField" && (
        <AddAppointmentFormTextField
          properties={properties as AppointmentTextFieldComponentProperties}
          onSuccess={onSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
      {componentType === "MultiSelect" && (
        <AddAppointmentFormMultiSelect
          properties={properties as AppointmentMultiSelectComponentProperties}
          onSuccess={onSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </ModalDialog>
  );
};

export default AppointmentFormModalDialog;
