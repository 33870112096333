import * as Sentry from "@sentry/react";
import LoadingOverlay from "react-loading-overlay-ts";
import Error from "../../pages/authentication/Error";
import Store from "../../store";

const SplitScreen = ({ children }: { children: any }) => {
  const { isLoading } = Store();
  const [left, right] = children;
  return (
    <Sentry.ErrorBoundary fallback={<Error />} showDialog={true}>
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Loading..."
        styles={{
          wrapper: { height: "100vh", display: "flex" },
        }}
      >
        <div className="flex overflow-hidden max-h-dvh">
          <div className="hidden overflow-auto basis-6/12 lg:block">{left}</div>
          <div className="overflow-auto grow lg:basis-6/12">{right}</div>
        </div>
      </LoadingOverlay>
    </Sentry.ErrorBoundary>
  );
};

export default SplitScreen;
