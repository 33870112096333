import AppointmentComponentProperties from "../../../models/appointments/AppointmentComponentProperties";
import AppointmentTemplateComponent from "../../../models/appointments/AppointmentTemplateComponent";
import AppointmentFormModalDialog from "./AppointmentFormModalDialog";

const EditAppointmentFormComponent = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  properties?: AppointmentComponentProperties;
  onSuccess: (component: AppointmentTemplateComponent) => void;
}) => {
  const { properties, onSuccess, isModalOpen, setIsModalOpen } = props;
  return (
    <>
      <AppointmentFormModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        properties={properties}
        onSuccess={(component: AppointmentTemplateComponent) => {
          onSuccess(component);
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default EditAppointmentFormComponent;
