import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AppointmentTemplateComponent from "../../../models/appointments/AppointmentTemplateComponent";
import AppointmentTextFieldComponent from "../../../models/appointments/AppointmentTextFieldComponent";
import AppointmentTextFieldComponentProperties from "../../../models/appointments/AppointmentTextFieldComponentProperties";
import Button from "../../ui/Button";
import TextField from "../../ui/TextField";

const AddAppointmentFormTextField = (props: {
  properties?: AppointmentTextFieldComponentProperties;
  onSuccess: (component: AppointmentTemplateComponent) => void;
  onCancel: () => void;
}) => {
  const { onSuccess, onCancel, properties } = props;
  const [componentName, setComponentName] = useState(
    properties?.componentName || "",
  );
  const [textFieldName, setTextFieldName] = useState(
    properties?.labelText || "",
  );
  return (
    <>
      <TextField
        elementId="component-name"
        labelText="Component name"
        onInput={setComponentName}
        value={componentName}
      />
      <TextField
        elementId="text-field-name"
        labelText="Text field name"
        onInput={setTextFieldName}
        value={textFieldName}
      />
      <div className="flex items-center justify-center space-x-5">
        <Button secondary type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          primary
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            onSuccess(
              new AppointmentTextFieldComponent({
                componentId: properties?.componentId || uuidv4(),
                componentType: "TextField",
                componentName: componentName,
                elementId: properties?.elementId || uuidv4(),
                labelText: textFieldName,
                value: "",
              }),
            );
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AddAppointmentFormTextField;
