import Button from "@/components/ui/Button";
import Appointment from "@/models/appointments/Appointment";
import { useState } from "react";

const AppointmentBookingTemplate = (props: {
  appointment: Appointment;
  onBack: (appointment: Appointment) => void;
  onSubmit: (appointment: Appointment) => void;
}) => {
  const { appointment, onBack, onSubmit } = props;
  const [updatedAppointment, setUpdatedAppointment] = useState(appointment);
  return (
    <div>
      <h1>Appointment Booking Template</h1>
      <div>
        {appointment.appointmentTemplate?.components.map((component, index) => (
          <div key={index} className="flex">
            {component.render()}
          </div>
        ))}
      </div>
      <div>
        <div className="flex justify-center space-x-4">
          <Button
            secondary
            type="button"
            onClick={() => {
              setUpdatedAppointment(appointment);
              onBack(updatedAppointment);
            }}
          >
            Back
          </Button>
          <Button
            primary
            type="button"
            onClick={() => {
              setUpdatedAppointment(appointment);
              onSubmit(updatedAppointment);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookingTemplate;
