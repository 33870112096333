import { useState } from "react";

const MultiSelect = (props: {
  prompt: string;
  items: string[];
  selectedItems: string[];
}) => {
  const { prompt, items } = props;
  const [selectedItems, setSelectedItems] = useState(props.selectedItems ?? []);

  const toggleItem = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((x) => x !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <div>
      <div className="mb-5 text-monkey-purple">{prompt}</div>
      <div className="flex flex-wrap mb-5 space-x-4">
        {items.map((x, i) => {
          const isSelected = selectedItems.includes(x);
          return (
            <div
              key={i}
              className={
                isSelected
                  ? "bg-monkey-purple text-white p-2 rounded-lg hover:cursor-pointer mb-4"
                  : "bg-monkey-gray text-black p-2 rounded-lg hover:cursor-pointer mb-4"
              }
              onClick={() => toggleItem(x)}
            >
              {x}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelect;
